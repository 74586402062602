import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/pic01.jpg'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

import Scroll from '../components/Scroll'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet
          title="Front Star - strony internetowe"
          htmlAttributes={{ lang: 'en' }}
        >
          <title>Front Star - strony internetowe</title>
          <meta
            name="description"
            content="Front Star - produkcja stron internetowych"
          />
        </Helmet>

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Tworzymy strony internetowe</h2>
                </header>
                <p>
                Tworzymy dedykowane strony internetowe, dbając o staranność wykonania,
                 design dostosowany do twoich klientów i kod strony stworzony z myślą o SEO i robotach 
                 przeszukujących internet. Zapewniamy nie tylko dedykowane projekty w najnowcześniejszym stylu,
                  ale również zwiększenie ruchu z popularnych wyszukiwarek poprzez zastosowanie najkorzystniejszych wzorców projektowych.

                </p>
                {/* <p>Zainteresowany?</p> */}
                <ul className="actions">
                  <li>
                    <Scroll type="id" element="footer" className="button">
                      Skontaktuj się z nami
                    </Scroll>
                  </li>
                </ul>
              </div>
              <span className="image">
                <img src={pic01} alt="" />
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Nasze specjalności</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style3 fa-paint-brush"></span>
                <h3>Twórczy Design</h3>
                <p>
                Każda stworzona przez nas strona jest zaprojektowana dla dedykowanego klienta.
                 Korzystamy z najnowszych rozwiązań UI/UX, aby dostosować ją nie tylko do Twoich gustów,
                  ale również do gustów Twoich klientów &ndash; aby każdy z nich czuł że to miejsce dla niego.
                </p>
              </li>
              <li>
                <span className="icon major style1 fa-code"></span>
                <h3>Nowoczesny kod</h3>
                <p>
                Nasz kod jest pisany przez developerów z dużym doświadczeniem w innych projektach.
                 Dbamy o to, aby nie tylko programiści byli pod wrażeniem naszych stron, 
                 ale również boty wyszukujące i pozycjonujące strony. 
                 Prawdziwe SEO zaczyna się już w kodzie strony.

                </p>
              </li>

              <li>
                <span className="icon major style5 fa-diamond"></span>
                <h3>Tworzenie marki</h3>
                <p>
                  Nasze strony mają na celu rozwijanie Twojej
                  marki. Pomożemy Ci stworzyć od podstaw Twoją tożsamość, abyś
                  uzyskiwał z niej jak największe przychody. Doradzimy i
                  przeprowadzimy Cię przez trudną drogę tworzenia tożsamości
                  firmy.
                </p>
              </li>
            </ul>
            {/* <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer> */}
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>Nasze statystyki</h2>
              <p>
              Wierzymy, że tylko prawdziwe dane są w stanie świadczyć o sukcesie, dlatego nie boimy się udostępniać własnych.
              </p>
            </header>
            <ul className="statistics">
              {/* <li className="style1">
                <span className="icon fa-code-fork"></span>
                <strong>5,120</strong> a
              </li> */}
              <li className="style5">
                <span className="icon fa-diamond"></span>
                <strong>16</strong>Stworzonych marek
              </li>
              <li className="style4">
                <span className="icon fa-laptop"></span>
                <strong>39</strong> Naszych stron online
              </li>
              <li className="style3">
                <span className="icon fa-clone"></span>
                <strong>23</strong>Odświeżonych stron
              </li>
              <li className="style2">
                <span className="icon fa-signal"></span>
                <strong>34,091</strong> Odwiedzin miesięcznie
              </li>
            </ul>
            <p className="major">
            Niezależnie od tego, czy odświeżasz swoją markę, czy tworzysz nową, 
            pomożemy Ci osiągnąć sukces w nowoczesnym świecie internetu.
            Będziemy pracować, nie bazując na odczuciach, lecz na twardych liczbach świadczących o rozwoju.

            </p>
            {/* <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer> */}
          </section>

          {/* <section id="cta" className="main special">
            <header className="major">
              <h2>Skontaktuj się z nami!</h2>
              <p>
                Jeżeli jeszcze nie jesteś pewien rozwiejemy twoje wątpliwości.{' '}
                <br></br> Czekamy na wiadomość od ciebie.
              </p>
              <p>
                <b>+48 723 727 723</b> <br></br> <b>kontakt(at)frontstar.pl</b>
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button special">
                    Get Started
                  </Link>
                </li>
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section> */}
        </div>
      </Layout>
    )
  }
}

export default Index
