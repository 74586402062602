import React from 'react'

import styled from 'styled-components'

import logo from '../assets/images/FRONT_STAR_full_white.svg'

const Header = props => (
  <header id="header" className="alt">
    <span className="logo">
      <LogoCss src={logo} alt="logo Front Star" />
    </span>
    <h1>Web development</h1>
    <p>
      Tworzymy nowoczesne strony  marzeń.{' '}
      <br></br>Pomagamy rozwijać marki w e-świecie.
    </p>
  </header>
)

export default Header

const LogoCss = styled.img`
  height: 20vh;
`
